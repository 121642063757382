/* Services.css */
.ServicesContainer {
    display: grid;
    gap: 2em;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    justify-items: center;
    margin-bottom: 10%;
    margin-left: 5%;
    margin-right: 5%;
}

.ServicesHeader {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center; 
    font-weight: bold;
    width: 100%; 
    margin-bottom: 50px; 
    margin-top: 100px;
    font-size: 42px;
    font-family: 'Oswald', sans-serif;
    color: #0C356A;
}

.ServiceCard {
    background-color: initial;
    border-radius: 10px;
    box-shadow: 0 4px 8px #0000001a;
    padding: 20px;
    text-align: center;
    transition: transform .3s ease;
    width: 80%;
    margin-bottom: 5%;
}

.ServiceCard:hover {
    transform: translateY(-10px);
}

.serviceCard-header {
    margin: 10px 0;
    font-size: 1.5rem;
    color: #0C356A;
    /* word-spacing: -0.3em; */
    font-family: 'Oswald', sans-serif;
}

.serviceCard-description {
    font-size: 1rem;
    color: #666;
    color: #0174BE;
    word-spacing: -0.3em;
}

.service-icon-1,
.service-icon-2,
.service-icon-3,
.service-icon-4,
.service-icon-5,
.service-icon-6 {
    color: #2999E4;
    color: #eeb504;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 50px;
    margin: 1em;
    padding: 0.3em;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .ServicesContainer {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 10%;
    }
}

@media (max-width: 750px) {
    .ServicesContainer {
        grid-template-columns: 1fr;
        margin-left: 0;
        margin-right: 0;
    }

    .ServicesHeader {
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
}
