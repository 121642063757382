
.PersonContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: 3em;
  margin: 40px 0;
  margin-left: 10%;
  margin-right: 10%;
}

@media screen and (min-width: 1650px) {
  .PersonContainer {
    margin-left: 13%;
  }
}

@media screen and (max-width: 1200px) {
  .PersonContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }
}

@media screen and (max-width: 750px) {
  .PersonContainer {
    grid-template-columns: 1fr; /* 1 card per row on smaller screens */
  }
}

.person-box {
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
}

.person-card {
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  width: 20em;
  height: 10em;
  margin: 20px;
  transition: all 0.3s ease;
  position: relative;
  overflow: visible;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 12em;
}

.person-card:hover {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.person-image {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 12px 12px;
  transition: opacity 0.3s ease;
}

.person-image-new {
  position: absolute;
  bottom: 0;
  left: 10%;
  object-fit: cover;
  transition: opacity 0.3s ease;
  opacity: 0; /* Hide by default */
}

.person-box:hover .person-image {
  opacity: 0; /* Hide original image on hover */
}

.person-box:hover .person-image-new {
  opacity: 1; /* Show new image on hover */
}

.person-name {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 5px;
  margin-top: 70%;
  color: #0C356A;
  /* word-spacing: -0.3em; */
  font-family: 'Oswald', sans-serif;

}

/* TÄSTÄ ALKAA EXTENDED KORTTI */ 

.expanded-card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expanded-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  max-width: 850px;
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  color: #333;
  z-index: 10000;
}


.expanded-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.small-box {
    width: 16em;
    height: 10em;
    background-color: rgba(249, 206, 1, 255);
    border-radius: 10px;
    position: absolute;
    z-index: 0;
    margin-top: 4.7em;
}

.person-image-extended-box {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.person-image-expanded {
  position: relative;
  top: calc(100% - 10em); /* Adjust to position the image at the bottom of the small-box */
  width: 70%;
  height: auto;
  border-radius: 12px;
  z-index: 1;
}


.person-name-extended {
  font-size: x-large;
  font-weight: bold;
  color: white;
  margin-top: 5%;
  font-family: 'Oswald', sans-serif;
}

.person-info,
.person-description,
.person-quote {
  text-align: center;
  color: #2ea4e6;
  color: #0174BE;
  font-size: 18px;
  word-spacing: -0.3em;
}

.person-description,
.person-quote {
  color: white;
}

.person-info-extended {
  color: white;
  word-spacing: -0.3em;
}

.person-quote {
    /* margin-top: 20%; */
    font-style: italic;
    font-weight: bold;
    margin: 0.5em;
}

.expanded-right {
  width: 50%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expanded-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}
.left-upper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}
.name-info {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left:2em;
}
.left-lower {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 42px;
  cursor: pointer;
  z-index: 10001; /* Ensure it is above all other elements */
}

.social-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 42px;
  margin: 10px;
}

.close-button:hover, .social-button:hover {
  color: #ccc;
}

.expanded-mobile {
  display: none;
}

.person-image-1 {
  width: 68%;
  clip-path: inset(0 0 30px 0); /* Adjust the bottom value (50px) as needed */
  top: -6.8em;
}

.person-image-new-1 {
  left: 5%;
  height: 180%;
}

.person-image-expanded-1, .person-image-expanded-5 {
    width: 12em;
    top: -0.3em;
    margin-bottom: 0;
    left: 1.5em;
    clip-path: inset(0 0 10px 0);
}

.person-image-2 {
  width: 80%;
  clip-path: inset(0 0 30px 0); /* Adjust the bottom value (50px) as needed */
  top: -7em;
}

.person-image-new-2 {
  left: 5%;
  width: 83%;
}

.person-image-expanded-2 {
    width: 15em;
    clip-path: inset(0 0 20px 0);
    top: -0.2em;
    /* left: 1em; */
}

.person-image-3 {
  width: 75%;
  left: 12.5em;
}

.person-image-new-3 {
  width: 85%;
  height: auto;
  /* top: -3.2em; */
  left: 3em;
  overflow: visible;
}

.person-image-expanded-3 {
    width: 15em;
    bottom: 1em;
    left: 1em;
    border-radius: 10px;
}

.person-image-4 {
  width: 65%;
  clip-path: inset(0 0 10px 0); /* Adjust the bottom value (50px) as needed */
  top: -6.7em;
}

.person-image-new-4 {
  width: 77%;
  left: 2.5em;
}

.person-image-expanded-4 {
  width: 13em;
  clip-path: inset(0 0 10px 0);
  bottom: 0.7em;
  left: 2em;
}

.person-image-5 {
  width: 65%;
  clip-path: inset(0 0 10px 0); /* Adjust the bottom value (50px) as needed */
  top: -7.2em;
}

.person-image-new-5 {
  left: 1.5em;
  width: 85%;
}

.person-image-6 {
  width: 35%;
  top: 1.5em;
  margin-bottom: -1em;
  left: 10em;
}

.person-image-new-6 {
  width: 60%;
  height: 120%;
  top: -3.5em;
  margin-bottom: -1em;
  left: 4em;
}

.person-image-expanded-6 {
    width: 14em;
    top: 0em;
    left: 1em;
    margin-bottom: 0em;
}

@media screen and (max-width: 750px) {

  .person-box {
    max-width: 100%;
  }

  .person-card {
    margin-inline: auto;
    max-width: 100%;
    max-height: 10em;
    margin-bottom: 8em;
    width: 18em;
  }

  .person-name {
    margin-top: 7em;
  }

  .person-info {
    width: 100%;
  }

  .person-name-extended {
    margin-top: 10%;
  }

  .person-image {
    object-fit: cover;
  }

  .person-image-new {
      margin-bottom: 30%;
      object-fit: contain;
  }

  .expanded-left, .expanded-right {
    display: none;
  }
  .expanded-mobile {
    display: block;
  }

  .expanded-card {
    flex-direction: column;
    width: 90%;
    height: auto;
    max-height: 100%;
    padding: 20px;
  }

  .person-image-expanded {
    width: 80%;
    margin-top: 10%;
    position: relative;
    margin-left: 10%;
  }

  .person-name-extended, 
  .person-info-extended, 
  .person-quote, 
  .person-description {
    text-align: center;
  }

  .small-box {
    width: 80%;
    bottom: 0;
    margin-left: 10%;
  }

  .person-info-extended {
    margin-bottom: -12%;
  }

  .person-info-extended,
  .person-quote,
  .person-description {
    font-size: smaller;
    margin: 0.5em
  }

  .social-buttons-container {
    justify-content: center;
    padding-right: 0;
    margin-top: 10px;
  }


  .person-image-1 {
    width: 65%;
    top: -4.2em;
  }

  .person-image-new-1 {
    width: 100%;
    height: 160%;
    top: -6em;
    left: -0.5em;
  }
  
  .person-image-expanded-1, .person-image-expanded-5 {
    width: 60%;
    top: -0.4em;
    margin-bottom: -1em;
    left: 2em;
  }

  .person-image-2 {
    width: 75%;
    top: -4em;
  }

  .person-image-new-2 {
    width: 100%;
    height: 150%;
    top: -5em;
    left: -0.5em;
  }

  .person-image-expanded-2 {
    width: 70%;
    left: 0.5em;
    top: 1.3em;
  }

  .person-image-3 {
    width: 65%;
    left: 12.1em;
  }

  .person-image-new-3 {
    height: 140%;
    top: -4em;
    left: 3.6em;
  }

  .person-image-expanded-3 {
    width: 70%;
    top: 0em;
    margin-bottom: 0em;
    left: 10%;
  }

  .person-image-4 {
    width: 65%;
    top: -5em;
  }

  .person-image-new-4 {
    width: 100%;
    height: 160%;
    top: -6em;
    left: 0em;
  }

  .person-image-expanded-4 {
    width: 60%;
    left: 2em;
    top: 0.6em;
  }

  .person-image-5 {
    width: 60%;
    top: -4.2em;
  }

  .person-image-new-5 {
    width: 80%;
    top: -4.5em;
    left: 2em;
  }

  .person-image-expanded-5 {
    width: 60%;
    top: -0.4em;
    left: 2em;
  }

  .person-image-6 {
    width: 40%;
    top: 1em;
    left: 9em;
  }

  .person-image-new-6 {
    width: 80%;
    top: -3em;
    left: 2em;
  }


  .person-image-expanded-6 {
    width: 60%;
    top: 0em;
    left: 2em;
  }
}
