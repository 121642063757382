body {
  font-size: large;
  background-color: aliceblue;
}

.backgroundimage {
  min-height: 50em;
  max-height: 50em;
}

.font {
  font-family: 'IBM Plex Mono', monospace;
}

.cookie-consent-center {
  text-align: left;
}

.cookie-consent-center .CookieConsent button {
  margin: 0 auto;
}

.cookie-consent-center .CookieConsentDeclineButton {
  margin: 0 auto;
}


.TopBar {
  background: darkslategrey;
  color: white;
  text-align: center;
}

.PersonContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  padding-bottom: 20px;
  padding-top: 30px;
  margin-bottom: 50px;
}


.firstRow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 5%;
}

.start_container {
  color: #2999E4
}

.HomePageTitle {
  padding: 10px;
  margin-bottom: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.TeamHeader{
  text-align: center;
  font-weight: bold;
  margin-top: 3em;
  margin-bottom: 2em;
  font-size: 42px;
  font-family: 'Oswald', sans-serif;
  color: #0C356A;
}

.modal {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  z-index: -1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal.open {
  opacity: 1;
  z-index: 999;
}

.modal-inner {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 15px 25px;
  text-align: center;
  width: 380px;
}

.modal-inner h2 {
  margin: 0;
}

.modal-inner p {
  line-height: 24px;
  margin: 10px 0;
}


.HomePageText {
  padding: 1.5em;
  padding-top: 15em;  
}

.Intro-text {
  font-size: large;
  color: #0174BE;
  word-spacing: -0.3em;
}

#rcc-decline-button {
  background-color: transparent !important;
}

.section-background {
  height: 450px;
}

.header-buttons {
  position: absolute;
  top: 2em;
  z-index: 3;
  padding: 1.5em;
}

.header-buttons a {
  margin-left: 1em; 
  font-size: 2em;
}

.headersome {
  color: white;
  font-size: 24px;
  margin-right: 30px;
  padding-inline: 10px;
  display: none;
}

/* .headersome:hover {
  border-color: rgba(249, 206, 1, 255);
} */


.linkbar {
  z-index: 10;
  position: relative;
  text-align: center;
  font-size: x-large;
  color: white;
}

.heading-font {
  font-weight: 500;
  color: white;
  text-decoration: none;
  margin-left: 20px;
  font-size: large;
  font-family: 'Oswald', sans-serif;
}

.heading-font:hover {
  color: rgba(249, 206, 1, 255);
}


.header-text {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: white;
  position: relative;
  bottom: 4em;
  font-size: xx-large;
  font-weight: bolder;
  font-family: 'Oswald', sans-serif;
}

.text-container {
  font-size: 22px;
  text-align: left;
  margin-top: 100px;
  margin-right: 0%;
}

.image-container {
  flex: 1;
}

/*   FOOTER   ---------------------------------------------   */

.footer {
  position: relative;
  background-color: #2999E4;
  color: white;
  margin-top: 250px;
}

.wave {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: auto; /* Adjust height as needed */
  transform: translateY(-98%); 
  z-index: 0; 
}

.footer-content {
  position: relative;
  z-index: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-left {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: left; /* Center align horizontally */
  text-align: left; /* Center align the text under the logo */
}

.footer-text,
.footer-ytunnus {
  margin-top: 10px; /* Add some space between the logo and text */
  word-spacing: -0.3em;
}


.footer-text {
  margin-left: 10px;
}


.footer-text-mobile {
  display:  none;
}

.footer-ytunnus-mobile {
  display: none;
}

.HomePageText2 {
  height: auto;
  position: absolute;
  right: 0;
  border-right: 1px solid black;
  padding-right: 10px;
}


.Links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;
  padding-right: 100px;
  color: white;
}

.Links button {
  height: 50px;
  width: 200px;
  margin-left: 100px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

button:hover {
  color: lightblue;
}

.PersonnelTitle {
  text-align: center;
}

.PersonDescription {
  width: 30%;
  height: auto;
  text-align: center;
  margin-top: 10%;
  margin-left: 35%;
}

.underline {
  text-decoration: underline;
}

.bolded {
  font-weight: bold;
}

.lottieplayer {
  width: 200px;
  height: 200px;
}

.lottieplayercontainer {
  display: flex;
  justify-content: center;
  align-items: center;

}

.keltainenlamppu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 20%;
  padding-top:5em;
  margin-left: 8%;
}

.bluetext {
  /* color: rgb(249,205,1,255);  */
  font-weight: bold;
  font-size: large;
  color: #0C356A;
  text-decoration: none;
}

.JesseKuva {
  width: 100%;
  height: 100%;
}

.shadow {
  background-color: white;
}

.h2 {
  font-family: 'Montserrat';
}

.my-button {
  background-color: aliceblue;
  padding-bottom: 40px;
}

.button-display {
  background-color: #2999E4;
  border-radius: 50px;
  padding-block: 5px;
  margin-inline: 10px;
}

.button-display:hover {
  background-color: rgb(147, 177, 243);
  cursor:pointer;
}

  .button-display {
    margin: 0;
    margin-inline: 100px;
    padding-block: 20px;
  }
  .my-button {
    margin-top: 100px;
  }


.my-button h2 {
  text-align: center;
  padding-inline: 10px;
  color: white;
}

.my-button button {
  background-color: #3498db;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.my-button button:hover {
  background-color: lightskyblue;
}

.start_container {
  background-color: white;
  position: relative; /* Add position relative to make absolute positioning work */
}

.welcome-container {
  position: relative;
}

.hide {
  display: none;
}

.show {
  display: block;
}

@media only screen and (min-width: 750px) {
  .WelcomeLamppu {
    width: 80px;
    top: 16%;
  }

  .WelcomeLogo {
    width: 300px;
    top: 21%;
  }

  .lottieplayercontainer {
    width: 20%;
  }
  .HomePageText {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-container {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .text-container p {
      width: 75%;
      max-width: 100em;
  }
  .wave {
    transform: translateY(-99%);
  }
}

@media (max-width: 750px) {

  .wave {
    transform: translateY(-130%);
  }

  .header {
    max-width: 100%;
  }

  .WelcomeLogo {
    margin-top: 20%; 
}

.WelcomeLamppu {
    margin-top: 15%; 
}
  .backgroundimage {
    max-height: 50em;
  }

  .linkbar {
    display: none;
  }

  .header-buttons {
    margin-left: 0;
    text-align: center;
  }

  .headersome {
    margin-left: 15px;
    font-size: 32px;
    border: none;
  }

  .header-text {
    top: 3.5em;
    max-width: 100%;
    text-align: center;
    font-size: x-large;
}
  

  .HomePageText {
    margin-top: 10em;
    padding: 1.5em;
}

  .keltainenlamppu {
    margin-top: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }

  .text-container {
    text-align: center;
  }

  .footer {
    margin-top: 80px;
    padding-bottom: 5px;
  }

  .footer-text {
    display: none;
  }

  .footer-text-mobile {
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
  }

  .footer-logo {
    display: block; /* Ensures the element is block level */
    padding-top: -10%;
  }

  .footer-ytunnus {
    display: none;
  }

  .footer-bottomrow-mobile {
  }

  .footer-ytunnus-mobile {
    display: flex; /* Ensure it takes up the full width */
    padding-bottom: 1%;
    text-align: center; /* Center-align the text */
    color: white;
    max-width: 100%;
  }

  .footer-text-mobile a {
    z-index: 999; /* Increase this if needed */
    position: relative;
  }
  
  .footer-ytunnus-mobile a {
    text-decoration: none;
    color: white;
    padding-left: 5px; /* Add space between the elements */
    z-index: 999;
  }

  .image-container img {
    padding-top: 100px;
  }

  .TeamHeader{
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
}