.video-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* filter: blur(4px); */
  }

  .backgroundimage {
    filter: blur(4px);
    width: 100%;
    object-fit: cover;
  }
  
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    /* filter: blur(1px) !important; */
  }

  h1 {
    margin-top: -100px;
    color: black;
  }

  