.pricing-container {
  padding: 40px;
  background-color: white;
}

.pricing-header {
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  color: #0C356A;
}

.pricing-sections {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  gap: 40px;
  justify-content: center;
}

.pricing-section {
  background-color: white;
  color: #2999E4; /* Blue text color */
  color: #0174BE;

  padding: 20px;
  text-align: center;
  margin-bottom: 60px;
}

.pricing-left, .pricing-right {
  width: 100%; /* Ensure full width inside grid layout */
}


.pricing-left {
  padding-left: 20%;
}

.pricing-right {
  padding-right: 20%;
}

.pricing-logo {
  display: block;
  margin: 0 auto;
  width: 120px;
}

.pricing-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #0C356A;
  /* word-spacing: -0.3em; */
  font-family: 'Oswald', sans-serif;
}

.pricing-price {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Oswald', sans-serif;

}

.discounted-price {
  text-decoration: line-through;
  color: #0C356A;
  margin-right: 10px;
  font-size: 24px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  margin-left: 7%;
  display: none;
}

.sale-price {
  font-size: 48px;
  margin-left: 1.5em;
}

.pricing-alv {
  font-weight: lighter;
  font-size: 12px;
  word-spacing: 0;
}

.pricing-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  word-spacing: -0.3em;
}

.pricing-lastcard-header {
  margin-top: 1em;
  font-size: xx-large;
}

.pricing-lastcard-title {
  font-size: large;
}

.pricing-button {
  color: #fff;
  border: none;
  padding: 20px;
  padding-inline: 20px;
  border-radius: 50px;
  font-size: medium;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  background-color: #0C356A;
  margin-right: 0;
}

.pricing-button:hover {
  background-color: #3b8cb8;
}

@media screen and (max-width: 859px) {
  .pricing-lastcard-header {
    margin-top: 1.5em;
    font-size: 26px;
  }

  .pricing-left {
    padding-left: 0%;
  }
  
  .pricing-right {
    padding-right: 0%;
  }
}

@media screen and (max-width: 955px) {
  .discounted-price {
    margin-left: 0%;
  }
}

/* Adjust layout for smaller screens */
@media screen and (max-width: 750px) {
  .pricing-sections {
    grid-template-columns: 1fr; /* One card per row */
  }

  .pricing-section {
    margin-bottom: 40px;
    width: 100%;
  }

  .pricing-header {
    margin-bottom: 5%;
  }

  .pricing-left, .pricing-right {
    padding-left: 0px;
    padding-right: 0px;
  }

  .discounted-price {
    margin-left: 11%;
    font-size: large;
  }

  .sale-price {
    font-size: 1.5em;
    margin-right: 8%;
    margin-left: 27%;
  }

  .pricing-alv {
    margin-left: -2em;
    word-spacing: -0.2em;

  }

  .pricing-price {
    width: 100%;
  }
}
