/* CookieConsent.css */
.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index: 1000;
    font-family: 'Trebuchet MS', sans-serif;
  }
  
  .cookie-consent-buttons {
    margin-top: 10px;
  }
  
  .cookie-consent button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: rgba(249, 206, 1, 255);
    color: black;
    border: none;
    cursor: pointer;
  }
  
  .cookie-consent button:hover {
    background-color: #2175b8;
  }
  