/* Header at the top */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background-color: transparent;
  padding: 10px 1em;
  box-sizing: border-box;
  transition: top 0.3s, background-color 0.3s;
}

.header.scrolled {
  background-color: #2999E4;
  max-width: 100%; 
}

.header-font {
  font-size: smaller;
  font-weight: 500;
  text-decoration: none;
  color: white;
  font-family: 'Oswald', sans-serif;
}

/* Navbar Links */
.navbar_links {
  display: flex;
  flex: 1;
  justify-content: space-around; /* Distribute links evenly */
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping */
  white-space: nowrap;
  font-family: 'IBM Plex Mono', monospace;
}

/* Style individual links */
.navbar_links a {
  margin: 0 0.5em; /* Reduce margin to make space for more items */
  color: white;
  text-decoration: none;
  flex-shrink: 0; /* Prevent shrinking */
}
.heading {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  color: white;
  margin: 0;
  animation: slide-in 3s ease-out;
}

.WelcomeLamppu {
  position: absolute;
  top: 4em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 10em;
}

.WelcomeLogo {
  position: absolute;
  top: 14em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 21.25em;
}
.mobile-flagbutton {
  top: 34px;
  left: 15px; 
  z-index: 1000; 
}  

.logo {
  width: 70px;
  height: auto;
}

.fin-flag {
  width: 60px;
  height: auto;
  margin-left: 50%;
}

.eng-flag {
  height: 35px;
}

.flag {
  width: 50px;
}

.animated-logo {
  animation: slide-in 3s ease-out;
}

.navbar {
  font-size: x-large;
  display: flex;
  align-items: center;

}
.navbar a {
  margin-right: 20px;
  margin-inline: 35px;
  color: white;
  text-decoration: none;
}

.navbar a:hover {
  color: black;
}

.navbar img {
  border-radius: 5px;
  width: 50px;
  height: 25px;
}

.navbar img:hover {
  filter: brightness(80%);
}

.navbar img:before {
  pointer-events: none;
  background-color: rgba(61, 61, 252, 0.1);
  transition: opacity 0.3s;
  opacity: 0;
}

.navbar:hover:before {
  opacity: 1;
}

.header-keltainenlamppu {
  width: 25%;
  margin-top: -10%;
  margin-bottom: 5%;
}

.navbar-nav {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background-color: #2999E4;
  font-size: 42px;
  top: 0;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed; /* Fixed positioning for full-page overlay */
  left: 0;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.navbar-nav.open {
  height: 100vh; /* Full height when open */
}

.navbar-nav.closed {
  height: 0; /* Hidden when closed */
}

.social-media {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icon {
  width: 40px;
  height: 40px;
}

.hamburger-chooser { 
  position: absolute;
  transition: transform 0.3s ease-in-out;
  color: white
}

.content {
  transition: margin-top 0.3s ease-in-out;
}

.hamburger-chooser.open + .content {
  margin-top: 200px; /* Adjust this value as needed */
}

.firstRow-header {
  display: flex;
  flex-direction: column; /* Change to column for full-page menu */
  width: 100%;
  align-items: center;
}

.firstRow-header a {
  color: white;
  text-decoration: none;
  margin: 10px 0; /* Vertical spacing */
}

.HeaderLamppu {
  width: auto;
  height: 50px;
  padding-left: 20px;
}

.HeaderLamppuMobile {
  width: auto;
  height: 50px;
}

@media only screen and (min-width: 750px) {
  .hamburger-chooser, .mobile-flagbutton, .HeaderLamppuMobile {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  
  .navbar_links {
    display: none !important;
  }

  .header {
    justify-content: space-between; 
    padding-right:5em;
  }

  .hamburger-chooser {
    margin-left: auto; 
    padding-right: 1em;
  }

  .HeaderLamppu {
    display: none;
  }

  .header-buttons {
    display: none;
  }

  .HeaderLamppuMobile {
    flex: 1;
    text-align: center; /* Center the logo */
  }
.mobile-flagbutton {
  margin-right: auto; /* Align flag to the left */
  padding-left: 1em;
}
  .header-keltainenlamppu {
    width: 20%;
    margin-bottom: 15%;
  }

  .header {
    max-width: 100%;
  }

  .header.scrolled {
    max-width: 100%;
  }

.eng-flag, .fin-flag {
  width: 3.5em;
  height: 2em;
  margin-left: 0;
}

  .header-font {
    padding-bottom: 7%;
  }
}

@media (max-width: 768px) {
  .header-buttons {
    display: none; /* Hide social media icons */
  }
}
/* Adjustments for medium screens */
@media only screen and (max-width: 1024px) {
  .navbar_links {
      flex-wrap: wrap; /* Allow wrapping if necessary */
      justify-content: space-around; /* Spread links evenly */
  }
  /* Style individual links */
.navbar_links a {
  margin: 0 0.5em; /* Reduce margin to fit more items */
  color: white;
  text-decoration: none;
  flex-shrink: 0; /* Prevent shrinking */
}

}