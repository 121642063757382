.projects-section {
  background-color: aliceblue;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
}

.projects-container {
  margin: 50px auto;
  padding-top: 100px;
  width: 80%;
  text-align: center;
}

.projects-header {
  margin-bottom: 50px;
  font-size: 42px;
  font-weight: bold; 
  font-family: 'Oswald', sans-serif;
  color: #0C356A;
}

.slick-slide {
  padding: 0 20px; /* Add padding to create space between slides */
}

.slick-prev:before, .slick-next:before {
  display: none;
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-card {
  height: 350px;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, background-color 0.2s ease;
  width: calc(25% - 40px); /* Adjust the width dynamically to fit four cards in a row */
  max-width: 300px; /* Set a maximum width for the cards */
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative; /* Add relative positioning */
  overflow: hidden; /* Hide the overflow for the ::before pseudo-element */
  z-index: 1;
}

.project-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100%;
  background-color: inherit;
  transition: bottom 0.5s ease, background-color 2s ease;
  z-index: -1;
}

.project-card:hover::before {
  bottom: 0;
}

.project-logo {
  width: 150px;
  max-height: 200px;
  height: auto;
  margin-bottom: 10px; /* Add margin bottom to separate logo from description */
}

.thaimarketolari-image {
  height: 100px;
  margin-bottom: 0.4em;
}

.keltainenlamppu-image {
  width: 100px;  /* Example size, adjust as needed */
  height: auto;
  margin-bottom: 0.3em;
}

.lamppu-image {
  width: 100px;
  height: auto;
  margin-bottom: 0.1em;
}

.taxi-image {
  width: 150px;
  margin-bottom: 0.4em;
  clip-path: inset(0 0 10px 0); /* Adjust the bottom value (50px) as needed */
}

.gale-image {
  padding-top: 20px;
  margin-bottom: 1em;
  margin-top: -1em;
  width: 70px;
  height: 105px;
}

.savolainen-image {
  width: 80px;
  margin-bottom: 0.2em;
}


.thaimarketolari-card:hover {
  background-color: red;
}

.heidi-card:hover {
  background-color: rgba(255,135,87,255);
}

.airporttaxi-card:hover {
  background-color: rgb(231, 223, 0);
  background-color: rgb(50, 50, 50);
}

.gale-card:hover {
  background-color: #6ABDE9;
}

.taxi2-card:hover {
  background-color: rgb(100, 100, 100);
}

.gale2-card:hover {
  background-color: rgb(80, 80, 80);
  background-color: #FDDE09;
}

.savolainen-image:hover {
  background-color: #FDDE09;
}

.project-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-card:hover .project-description h3,
.project-card:hover .project-description p,
.project-card:hover .visit-page p, 
.project-card:hover .visit-page svg {  
  color: white;
}

.project-title {
  margin-top: 10px;
  font-weight: bold;
  color: #0C356A;
  /* word-spacing: -0.3em; */
  font-family: 'Oswald', sans-serif;


}

.project-description {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #0174BE;
}

.project-description-text {
  margin-top: -1em;
  word-spacing: -0.3em;
}

.slick-slide img {
  display: inline !important;
}

.project-description h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.project-description p {
  font-size: 1rem;
  text-align: center;
  letter-spacing: -0.05em;
}

.visit-page {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: italic;
  color: #0C356A;
}

.visit-page p {
  margin-right: 5px;
}

.visit-page svg {
  vertical-align: middle;
  margin-top: -12px;
}

.project-link {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 750px) {
  
  .project-card {
    width: calc(100% - 40px);
    margin-left: 0;
    height: 25em;
  }

  .project-title {
    margin-top: 5%;
    font-weight: bold;
  }

  .project-description-text {
    margin-top: 0%;
}

  .project-description h3 {
    font-size: 24px;
  }

  .project-description p {
    font-size: medium;
  }
}
