.TechnologiesSection {
    display: flex;
    justify-content: space-between;
    background-color: aliceblue;
    padding-top: 5%;
  }

.TechnologiesText {
    padding-top: 100px;
    margin-left: 15%;
    width: 30%;
    font-size: 24px;
}

.Technologies-description p {
  text-align: left;
  font-size: 26px;
}


.TechnologiesTitle {
    text-align: left;
    color: rgb(98, 82, 188);
    font-weight: bold;
    margin-bottom: 5%;
}


.TechnologiesLogos {
  display: flex;
  align-items: center;
  margin-right: 20%;
  flex-wrap: wrap;
  justify-content: center;
  margin-block: 300px;
  margin-top:150px;
}

.first-row {
    display: flex;
  }
  
  .second-row {
    display: flex;
  }
  
  .logo {
    color: rgb(98, 82, 188);
    margin: 15px;
    animation: logo-pulse 2s infinite;
  }

  @keyframes logo-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @media screen and (min-width: 750px) {
    .TechnologiesSection {
      margin-bottom: 0px;
    }
  }


@media screen and (max-width: 750px) {
    .TechnologiesSection {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        padding-top: 400px;
    }

    .Technologies-description p {
      text-align: center;
      font-size: 26px;
    }
    
    .TechnologiesText {
        width: auto;
        font-size: 20px;
        margin-right: 10%;
        text-align: center;
    }

    .TechnologiesTitle {
        margin-bottom: 40px;
        text-align: center;
    }
    .TechnologiesLogos {
      margin-right: 0;
      margin-block: 0px;
      margin-bottom: 100px;
    }
  }
  