.contact-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: #2999E4;
  padding: 20px;
}

.contact-section-left {
  margin-left: 5%;
  margin-right: 5%;
}

.text-section {
  flex: 1;
  padding-right: 20px;
}

.form-section {
  flex: 1;
  margin-right: 5%;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 0 10px;
  margin-right: 5%;
}

label {
  font-weight: bold;
  margin: 10px 0;
}

input[type="text"], input[type="email"], textarea {
  padding: 1em;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(249, 206, 1, 255);
  transition: background-color 0.2s ease;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: -20px;
}

textarea {
  padding-top: 30px;
  text-align: left;
}

input[type="text"]:hover, input[type="email"]:hover, textarea:hover {
  background-color: #ffe484;
}


input[type="submit"]:hover {
  background-color: #74c1e8;
}

input[type="submit"] {
  background-color: #2999E4;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 20px;
  margin: 20px auto;
  cursor: pointer;
  border-radius: 5px;
  width: 200px;
  box-sizing: border-box;
}

.green-button {
  background-color: green !important;
}

.contact-heading {
  text-align: left;
  font-weight: bold;
  font-size: xx-large;
  font-family: 'Oswald', sans-serif;
  color: #0C356A;
}

.some-header {
  font-size: xx-large;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  color: #0C356A;
}

.phonenumber {
  color: #0C356A;
  font-weight: bold;
  transition: color 0.1s ease;
  text-decoration: none;
  word-spacing: -0.3em;
}

.phonenumber:hover {
  color: rgba(249, 206, 1, 255);
}

.phonenumber-text {
  color: #0174BE;
}

.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.error-message.fade-out {
  opacity: 0;
}

.success-message {
  color: green;
  margin-top: 10px;
  text-align: center;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.success-message.fade-out {
  opacity: 0;
}

.some-section {
  margin-top: 50px;
  text-align: left;
}

.some-description, .contact-description {
  margin-top: 30px;
  color: #0174BE;
  word-spacing: -0.3em;
}

.some-buttons {
  margin-top: 20px;
  position: relative;
  z-index: 999;
}

.fab {
  transition: color 0.2s ease;
  border: 1px solid red;
}

.footerfab {
  color: #0C356A;
  font-size: 36px;
  margin-right: 2em;
}

.footerfab:hover {
  color: rgba(249, 206, 1, 255);
}

.some-section-mobile {
  display: none;
}

@media (max-width: 750px) {

  .contact-container {
    flex-direction: column;
  }

  .text-section, .form-section {
    width: 100%;
    padding: 0;
  }

  .contact-heading {
    font-size: 32px;
    text-align: center;
  }

  .contact-description {
    text-align: center;
    word-spacing: -0.3em;
  }

  .phonenumber-div {
    text-align: center;
  }

  .phonenumber-text {
    word-spacing: -0.4em;
  }

  .phonenumber {
    word-spacing: -0.4em;
  }

  .contact-section-left {
    margin-left: 0;
  }

  .some-header {
    font-size: xx-large;
  }

  .some-section {
    display: none;
  }

  .some-description {
    text-align: center;
    font-size: 18px;
  }

  .some-section-mobile {
    display: inline-block;
    margin-bottom: 50px;
    text-align: center;
  }

  .fab {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .footerfab {
    font-size: 42px;
    margin-left: 0.5em;
    margin-right: 0.8em;
    margin-top: 0.5em;
  }
}

